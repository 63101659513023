.App {
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content:center
}

.icon-span {
  margin:10px;
}
.thumb img {
  width: 15vw;
}